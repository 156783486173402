import axios from "axios";

export default {
  async postMessage(messageData) {
    return axios
      .post("/message", messageData)
      .then((response) => {
        return {
          success: true,
          message: {
            GRE: "Το μήνυμα σου στάλθηκε με επιτυχία",
            ENG: "Your message was sent successfully",
          },
        };
      })
      .catch((error) => {
        return {
          success: false,
          message: {
            GRE: "Κάτι πήγε λάθος",
            ENG: "Something went wrong",
          },
        };
      });
  },
};
