<template>
  <div id="info">
    <WhiteHeader :isApplication="isApplication" :showNavigation="false"></WhiteHeader>
    <div
      id="mask"
      @click="onClickOutside"
      :class="{ mask: isLanguageModalOpen || isEntranceModalOpen }"
    ></div>
    <div id="body">
      <div id="titles-container">
        <div
          :class="{ selected: title.isSelected }"
          v-for="title in titles"
          :key="title.id"
          id="title"
        >
          <h4
            :class="{ selectedText: title.isSelected }"
            @click="select(title.id)"
          >{{ labels.info[title.title][language] }}</h4>
        </div>
      </div>
      <div
        id="terms-of-service-container"
        v-if="$router.currentRoute.params.type == 'terms-of-service'"
      >
        <div class="info-type">
          <h4>Όροι Χρήσης</h4>
        </div>
        <div class="info-title">
          <h4>Γενικά</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η DEVINO ΕE, εφεξής η «Εταιρεία», δημιούργησε την παρούσα {{ isApplication ? "εφαρμογή" : "ιστοσελίδα"}}
            {{ isApplication ? "" : "Fluter.gr"}} (η «Ιστοσελίδα»), με σκοπό να προσφέρει την δυνατότητα
            πραγματοποίησης παραγγελιών εδεσμάτων, αναψυκτικών, ποτών και κάθε
            είδους προϊόντων που προσφέρονται στους καταναλωτές από
            συνεργαζόμενα εστιατόρια, ξενοδοχεία και λοιπά καταστήματα
            υγειονομικού ενδιαφέροντος (τα «Καταστήματα»), καθώς και την
            δυνατότητα υποβολής διαφόρων αιτημάτων για παροχή υπηρεσιών
            σχετιζόμενων με την δραστηριότητα των Καταστημάτων. Κατά το χρόνο
            της παραγγελίας θα πρέπει να είστε πελάτες κάποιου Καταστήματος,
            δηλαδή να βρίσκεστε στο χώρο του Καταστήματος με σκοπό να προβείτε
            σε παραγγελία προϊόντων ή υπηρεσιών. Για να μπορέσετε να προβείτε σε
            παραγγελία μέσω της Ιστοσελίδας, θα πρέπει να σκανάρετε τον κωδικό
            QR της Εταιρείας που θα βρείτε στο Κατάστημα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η χρήση της Ιστοσελίδας, των υπηρεσιών που προσφέρονται μέσα από
            αυτήν και του υλικού που είναι αναρτημένο ή αποτελεί τμήμα ή
            περιεχόμενο της Ιστοσελίδας (στο εξής το «Υλικό») υπόκειται στους
            παρόντες Όρους Χρήσης, όπως ισχύουν κάθε φορά. Οι Όροι Χρήσης
            αποτελούν τη συμφωνία μεταξύ της Εταιρείας και κάθε χρήστη της
            Ιστοσελίδας.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Κάνοντας χρήση της Ιστοσελίδας (στην οποία χρήση περιλαμβάνεται και
            η πλοήγηση της Ιστοσελίδας) θεωρείται ότι αποδέχεστε τους Όρους
            Χρήσης, τους οποίους σας συνιστούμε να διαβάσετε πριν κάνετε χρήση
            της Ιστοσελίδας. Σε περίπτωση διαφωνίας με τους Όρους Χρήσης,
            καλείστε να μην κάνετε χρήση της Ιστοσελίδας. Μπορείτε να
            επικοινωνήσετε μαζί μας μέσω της Ιστοσελίδας ή μέσω e-mail στην
            ηλεκτρονική διεύθυνση info@fluter.gr.
          </h4>
        </div>
        <div class="info-title">
          <h4>Αλλαγές / Τροποποιήσεις Όρων Χρήσης</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία διατηρεί το δικαίωμα να τροποποιεί τους Όρους Χρήσης
            οποτεδήποτε και με άμεση ισχύ, αναρτώντας στην Ιστοσελίδα τους
            τροποποιημένους Όρους Χρήσης, καθώς και το δικαίωμα να αλλάζει,
            αναστέλλει ή διακόπτει οποιαδήποτε επιμέρους λειτουργία της
            Ιστοσελίδας. Κάνοντας χρήση της Ιστοσελίδας θεωρείται ότι αποδέχεστε
            τους Όρους Χρήσης ως ισχύουν τη στιγμή που γίνεται η χρήση.
          </h4>
        </div>
        <div class="info-title">
          <h4>Διόρθωση, Τροποποίηση ή Διαγραφή Πληροφοριών</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία επιτρέπει σας επιτρέπει να προβαίνετε σε αλλαγές,
            συμπληρώσεις, προσθήκες ή διαγραφές δεδομένων και πληροφοριών που
            έχετε καταχωρήσει στην Ιστοσελίδα μέσω αυτής. Για αλλαγές στα
            προσωπικά σας δεδομένα, παρακαλείστε να διαβάσετε την Πολιτική
            Προστασίας Προσωπικών Δεδομένων.
          </h4>
        </div>
        <div class="info-title">
          <h4>Αλλεργιογόνα</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Αν έχετε αλλεργία ή δυσανεξία σε κάποιο φαγητό, θα πρέπει να μας
            ενημερώσετε αποστέλλοντάς μας σχετικό σχόλιο μέσω της παραγγελίας
            σας. Θα προωθήσουμε το σχόλιό σας στο Κατάστημα μαζί με την
            παραγγελία σας. Μπορείτε επίσης να επικοινωνήσετε απευθείας με το
            Κατάστημα προκειμένου να ενημερώσετε το Κατάστημα και να ζητήσετε
            πληροφορίες ή διευκρινίσεις σχετικά με συστατικά και τρόπους
            παρασκευής.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Δεν φέρουμε καμία ευθύνη σε περίπτωση που παραλείψετε να μας
            ενημερώσετε για τυχόν αλλεργίες ή δυσανεξίες που αντιμετωπίζετε. Σε
            περίπτωση που μας ενημερώσετε, η ευθύνη μας περιορίζεται
            αποκλειστικά στην υποχρέωση διαβίβασης της σχετικής πληροφορίας στο
            Κατάστημα και δεν φέρουμε καμία ευθύνη για τις μετέπειτα ενέργειες
            του Καταστήματος ούτε παρέχουμε οποιαδήποτε εγγύηση ότι τα προϊόντα
            που πωλούνται από τα Καταστήματα δεν περιέχουν αλλεργιογόνα. Τα
            Καταστήματα φέρουν αποκλειστική και πλήρη ευθύνη για την ποιότητα,
            συντήρηση, αποθήκευση και έλεγχο των τροφίμων και προϊόντων τα οποία
            παραγγέλνετε μέσω της Ιστοσελίδας, καθώς και για την ακρίβεια και
            πληρότητα τυχόν αναγκαίων επισημάνσεων και περιγραφών σχετικά με τα
            συστατικά και τα αλλεργιογόνα που περιέχονται στα προϊόντα του
            καταλόγου τους.
          </h4>
        </div>
        <div class="info-title">
          <h4>Προσφορές</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Παρέχουμε την δυνατότητα στα Καταστήματα να δημιουργούν προσφορές
            μέσω της Ιστοσελίδας, οι οποίες είναι διαθέσιμες σε εσάς. Η
            δημιουργία προσφορών εναπόκειται στην απόλυτη διακριτική ευχέρεια
            των Καταστημάτων και η Εταιρεία δεν φέρει καμία σχετική ευθύνη.
          </h4>
        </div>
        <div class="info-title">
          <h4>Πολιτική παράδοσης πρϊόντων</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Με την επιβεβαίωση της παραγγελίας ο Πελάτης ενημερώνεται με μήνυμα στην οθόνη του
            ότι η παραγγελία του έγινε αποδεκτή απο το Κατάστημα. Τα προϊόντα αποστέλλονται στο σημείο
            εντός του καταστήματος ή στην διεύθυνση την οποία έχει υποδείξει ο εκάστοτε πελάτης κατά την
            υποβολή της παραγγελίας του. Ο Πελάτης είναι υπεύθυνος για την ορθότητα των παρεχόμενων πληροφοριών
            αποστολής και παράδοσης της παραγγελίας. Σε περίπτωση σφάλματος κατά την παροχή από τον Πελάτη
            των σχετικών στοιχείων επικοινωνίας του παραλήπτη, η Εταιρεία δεν μπορεί να θεωρηθεί υπεύθυνη για
            το γεγονός ότι το προϊόν δεν μπορεί να παραδοθεί. Ο χρόνος παράδοσης εξαρτάται από τον φόρτο
            εργασιών που έχει το κατάστημα την στιγμή που ο Πελάτης υπέβαλλε την παραγγελία του.
            Ουδεμία ευθύνη δύναται να βαρύνει την Εταιρεία για καθυστερήσεις στην παράδοση που δεν εμπίπτουν
            στον έλεγχο της Εταιρείας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τρόποι Πληρωμής</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Σας παρέχουμε την δυνατότητα να εξοφλήσετε την παραγγελία σας προς
            το Κατάστημα μέσω της Ιστοσελίδας πληρώνοντας με πιστωτική ή
            χρεωστική κάρτα. Εναλλακτικά μπορείτε να καταβάλετε το αντίτιμο της
            παραγγελίας στο Κατάστημα με τους τρόπους που ορίζονται από αυτό.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η πληρωμή με κάρτα μέσω της Ιστοσελίδας είναι απόλυτα ασφαλής. Ο
            χρήστης που επιλέγει την πληρωμή με κάρτα οδηγείται σε ασφαλή
            διακομιστή της EVERYPAY η οποία είναι πιστοποιημένο κατά PCI DSS
            Level 1 και εποπτευόμενο από την Τράπεζα της Ελλάδος Ίδρυμα
            Πληρωμών. Στη συνέχεια, ο χρήστης δίνει τα στοιχεία της του κάρτας
            και σε περίπτωση που αυτή πάρει έγκριση, επιστρέφει στην Ιστοσελίδα
            και ειδοποιείται για την έκβαση της συναλλαγής.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Αναγνωρίζοντας τη σημασία της ασφάλειας των ηλεκτρονικών πληρωμών, η EveryPay είναι αδειοδοτημένο
            Ίδρυμα Πληρωμών από την Τράπεζα της Ελλάδος (αρ. απόφασης 280/3/23-7-2018 ΦΕΚ Β 3010/25-7-2018),
            και διαχειρίζεται με ασφάλεια στοιχεία συναλλαγών πληρωμών με κάρτες, σύμφωνα με το κανονιστικό
            πλαίσιο του προτύπου διαχείρισης ασφάλειας συναλλαγών με κάρτες. Η Everypay είναι πιστοποιημένη
            σύμφωνα με το πρότυπο διασφάλισης ασφάλειας διαχείρισης συναλλαγών με κάρτες (PCI DSS), και
            υπόκειται σε περιοδικούς ελέγχους από εξειδικευμένη συμβουλευτική εταιρία. Όλες οι υπηρεσίες της
            Everypay γίνονται μέσα από ασφαλείς συνδέσεις με πιστοποιητικά 256 bit SSL. Η EveryPay υποστηρίζει
            επίσης τη δυνατότητα χρήσης της υπηρεσίας 3D Secure, μία επιπρόσθετη δικλίδα ασφαλείας για κάρτες
            VISA και MasterCard. Ο Πληρωτής τότε θα πρέπει να πληκτρολογήσει τον προσωπικό του μυστικό κωδικό,
            για να ολοκληρώσει με επιτυχία την συναλλαγή
          </h4>
        </div>
        <div class="info-title">
          <h4>Πολιτική επιστροφής χρημάτων</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Σε περίπτωση που έχετε πληρώσει με κάρτα και μετά την διαβίβαση της
            παραγγελίας σας στο Κατάστημα, η Εταιρεία ενημερωθεί ότι αυτή δεν μπορεί να
            εκτελεστεί λόγω έλλειψης προϊόντων ή για οποιαδήποτε άλλη αιτία, τα
            χρήματα σάς επιστρέφονται. Η επιστροφή γίνεται από την Εταιρεία η
            οποία και φέρει την μοναδική ευθύνη για την επιστροφή των χρημάτων
            στην κάρτα που χρησιμοποιήθηκε. Ο καταναλωτής δεν επιβαρύνεται σ'
            αυτή την περίπτωση για την επιστροφή χρημάτων.
          </h4>
        </div>
        <div class="info-title">
          <h4>Χρήση της Ιστοσελίδας από ανήλικους</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Με την αποδοχή των Όρων Χρήσης δηλώνετε ότι είστε ενήλικος άνω των
            18 ετών ή, εάν είστε κάτω των 18 ετών, ότι έχετε λάβει την
            απαραίτητη συναίνεση από τον έχοντα/ τους έχοντες τη γονική μέριμνά
            σας, ιδίως όταν στην παραγγελία περιλαμβάνονται αλκοολούχα ποτά
            ή/και προϊόντα ζύθου και/ή προϊόντα καπνού, και ότι θα παράσχετε τα
            στοιχεία αυτού/αυτών εφόσον ζητηθεί. Σε κάθε περίπτωση κατά τον
            χρόνο παράδοσης της παραγγελίας, τα Καταστήματα διατηρούν το
            δικαίωμα να ζητήσουν επιβεβαίωση της ηλικίας σας, ώστε να
            διασφαλιστεί η τήρηση της νομοθεσίας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Eγγραφή / λογαριασμός μέλους</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Για την εγγραφή σας στην Ιστοσελίδα όπου και εφ’ όσον απαιτείται,
            συμφωνείτε να: α) παρέχετε αληθείς, ακριβείς, έγκυρες και πλήρεις
            πληροφορίες σχετικά με τα στοιχεία που του ζητούνται από την
            Εταιρεία στις σχετικές αιτήσεις για πρόσβαση στν Ιστοσελίδα και/ή το
            Υλικό και β) διατηρείτε και να ενημερώνει τα στοιχεία εγγραφής σας
            ώστε να διατηρούνται αληθή, ακριβή, έγκυρα, ενημερωμένα και πλήρη.
            Μόλις ολοκληρώσετε τη διαδικασία εγγραφής που έχει ορίσει η
            Εταιρεία, θα σας σταλεί μήνυμα προς επιβεβαίωση του τηλεφωνικού
            αριθμού που έχετε δηλώσει κατά την εγγραφή σας. Είστε αποκλειστικά
            υπεύθυνοι για όλες τις πράξεις που διενεργούνται υπό τον προσωπικό
            κωδικό πρόσβασης, και γενικότερα τον λογαριασμό σας (user account)
            και οφείλετε να μην αποκαλύπτεται σε τρίτα πρόσωπα τον κωδικό
            πρόσβασής. Σε περίπτωση κλοπής ή απώλειας του κωδικού πρόσβασης,
            καθώς και σε κάθε περίπτωση που έχει λάβει χώρα ή πιθανολογείτε ότι
            έλαβε χώρα μη εξουσιοδοτημένη χρήση του λογαριασμού σας και/ή
            παραβίαση ασφαλείας, θα πρέπει να ενημερώσετε άμεσα την Εταιρεία. Η
            Εταιρεία δεν ευθύνεται για οποιαδήποτε βλάβη ή ζημία προκύψει από
            την μη εξουσιοδοτημένη χρήση του λογαριασμού σας, η οποία οφείλετε
            στην αδυναμία σας να συμμορφωθείτε με τους παρόντες Όρους Χρήσης
            και/ή να διαφυλάξετε την μυστικότητα του κωδικού χρήστη που έχετε
            ορίσει.
          </h4>
        </div>
        <div class="info-title">
          <h4>Κώδικας Δεοντολογίας Μελών</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η χρήση της Ιστοσελδίας και του Υλικού προϋποθέτει τη συμφωνία σας
            με τους ακόλουθους κανόνες συμπεριφοράς:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>i.</strong> Δεν επιτρέπεται να προβαίνετε σε παραγγελίας
            μέσω της Ιστοσελίδας αν δεν βρίσκεστε στο χώρο του Καταστήματος,
            ούτε να προβαίνετε σε παραγγελίες αν δεν έχετε πραγματική βούληση
            αγοράς των παραγγελλόμενων προϊόντων.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>ii.</strong> Είστε αποκλειστικά υπεύθυνοι για το τυχόν
            περιεχόμενο που αναρτάτε, δημοσιεύετε, αποστέλλετε, μεταφέρετε ή
            καθιστάτε με οποιοδήποτε τρόοπο διαθέσιμο μέσω της Ιστοσελίδας. Η
            Εταιρεία δεν είναι δυνατόν, λόγω του όγκου του, να ελέγχει το σύνολο
            του περιεχομένου που τυχόν αναρτάται από τους χρήστες, οπότε δεν
            εγγυάται την ακρίβεια, την ακεραιότητα, τη νομιμότητα, ή την
            ποιότητα τέτοιου περιεχομένου.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>iii.</strong> Η Εταιρεία δεν κάνει προκαταρκτικό έλεγχο του
            περιεχομένου που αναρτάται από τους χρήστες. Διατηρεί όμως το
            δικαίωμα να αρνηθεί την ανάρτηση στην Ιστοσελίδα ή να προβεί σε
            μετακίνηση ή διαγραφή περιεχομένου που διατίθεται της Ιστοσελίδας,
            ιδίως σε περίπτωση που αυτό παραβιάζει τους παρόντες Όρους Χρήσης ή
            την κείμενη νομοθεσία.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>iv.</strong> Οφείλετε να τηρείτε την κείμενη νομοθεσία που
            αφορά την μετάδοση δεδομένων από την Ελλάδα και την Ευρώπη προς
            τρίτα κράτη.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>v.</strong> Αποδέχεστε ότι χρησιμοποιώντας την Ιστοσελίδα
            ενδέχεται να εκτεθείτε σε περιεχόμενο προσβλητικό, ανήθικο ή
            παράνομο. Σε περίπτωση που η Εταιρεία λάβει ειδοποίηση ότι
            οποιοδήποτε περιεχόμενο προκαλεί βλάβη σε τρίτο πρόσωπο, διατηρεί το
            δικαίωμα να προβεί στην άμεση διαγραφή του περιεχομένου αυτού και
            ταυτόχρονα να διακόψει τη λειτουργία του λογαριασμού του χρήστη ο
            οποίος παραβιάζει τους Όρους Χρήσης.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>vi.</strong> Εφόσον μέσω της Ιστοσελίδας είναι δυνατή η
            διενέργεια συνομιλιών στο Διαδίκτυο, θα πρέπει να ακολουθείται ο
            κώδικας συμπεριφοράς χρηστών internet (NETIQUETTE) και απαγορεύεται
            η εκ μέρους των χρηστών υιοθέτηση αθέμιτων πρακτικών.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>vii.</strong> Σε περίπτωση μη συμμόρφωσης των χρηστών με
            τους παρόντες Όρους Χρήσης και την ισχύουσα νομοθεσία, η Εταιρεία
            επιφυλάσσεται του δικαιώματος της να προβεί σε κάθε αναγκαία
            ενέργεια, όπως σε απαγόρευση πρόσβασής τους σε όλες ή τμήμα των
            παρεχομένων υπηρεσιών, σε διαγραφή, μετακίνηση, επεξεργασία των
            μηνυμάτων και του περιεχομένου που αναρτάται από χρήστες, καθώς και
            σε αναστολή της παροχής υπηρεσιών ή σε διαγραφή των λογαριασμών
            χρηστών που παραβιάζουν του παρόντες Όρους Χρήσης και/ή την κείμενη
            νομοθεσία.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>viii.</strong> Δεν επιτρέπεται κατά τη χρήση της
            Ιστοσελίδας:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή
            χρήση άλλης μεθόδου για την εγκατάσταση περιεχομένου που είναι
            παράνομο, επιβλαβές, απειλητικό, προσβλητικό, επιζήμιο,
            δυσφημιστικό, χυδαίο, βίαιο, υβριστικό, ρατσιστικό ή άλλως
            αποδοκιμαστέο, παραβιάζει την προσωπικότητα και τα προσωπικά
            δεδομένα άλλων προσώπων ή προκαλεί συναισθήματα μίσους.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή
            χρήση άλλης μεθόδου για την εγκατάσταση μη εξουσιοδοτημένης
            διαφήμισης ή άλλου περιεχομένου προώθησης προϊόντων, την αποστολή
            ανεπιθύμητων ηλεκτρονικών μηνυμάτων (e-mails) και οποιαδήποτε άλλη
            μορφή ανεπιθύμητης προώθησης περιεχομένου. Η παρενόχληση με
            οποιονδήποτε τρόπο της ιδιωτικής ζωής και των ατομικών και
            κοινωνικών δικαιωμάτων άλλων χρηστών (όπως η συλλογή ή/και
            αποθήκευση προσωπικών δεδομένων άλλων χρηστών/μελών)
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η ψευδής δήλωση για την ταυτότητα του χρήστη ή
            παραπλανητική δήλωση αναφορικά με τη σχέση ή/και συνεργασία του
            χρήστη με κάποιο άλλο νομικό ή φυσικό πρόσωπο, επίσης η αλλοίωση των
            αναγνωριστικών των χρηστών με σκοπό την παραπλάνηση ως προς την
            προέλευση του περιεχομένου που μεταδίδεται μέσω της Ιστοσελίδας.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή
            χρήση άλλης μεθόδου για την εγκατάσταση περιεχομένου από άτομο που
            δεν έχει δικαίωμα να διαθέσει το συγκεκριμένο περιεχόμενο βάσει
            νόμου ή βάσει εμπιστευτικής σχέσης και/ή που παραβιάζει οποιαδήποτε
            δικαιώματα πνευματικής και βιομηχανικής ιδιοκτησίας οποιουδήποτε
            φορέα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή
            χρήση άλλης μεθόδου για την εγκατάσταση ή/και προώθηση ή/και διάθεση
            περιεχομένου που περιέχει ψηφιακούς ιούς ή οποιοδήποτε άλλο
            ηλεκτρονικό κώδικα, αρχεία ή προγράμματα σχεδιασμένα να
            παρεμβληθούν, καταστρέψουν ή περιορίσουν τη λειτουργία οποιουδήποτε
            λογισμικού ή εξοπλισμού ηλεκτρονικών υπολογιστών ή τηλεπικοινωνιακού
            εξοπλισμού.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η παρεμβολή στις υπηρεσίες ή διάσπαση των
            υπηρεσιών ή των servers ή των δικτύων που είναι συνδεδεμένα με την
            Ιστοσελίδα, ή η παραβίαση των προϋποθέσεων, διαδικασιών και κανόνων
            χρήσης των δικτύων αυτών.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>ix.</strong> Απαγορεύεται οποιαδήποτε άλλη βλαπτική ενέργεια
            μέσω της Ιστοσελίδας η οποία παραβιάζει τους παρόντες Όρους και η
            οποία ενδέχεται να προξενήσει ζημία στην Εταιρεία, στα Καταστήματα ή
            σε τρίτους, ακόμη και αν δεν αναφέρεται ρητά στους παρόντες Όρους.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Σε περίπτωση παραβίασης των παρόντων Όρων από μέρους σας, η Εταιρεία
            διατηρεί το δικαίωμα να προβεί σε αναστολή της χρήσης ή διαγραφή του
            λογαριασμού σας, καθώς και σε κάθε άλλη εκ του νόμου προβλεπόμενη
            ενέργεια προς αποκατάσταση της ζημίας που τυχόν υπέστη.
          </h4>
        </div>
        <div class="info-title">
          <h4>Κώδικας Δεοντολογίας Μελών</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία ευθύνη μόνο για την διαβίβαση των παραγγελιών στα
            Καταστήματα και δεν φέρει καμία ευθύνη για την εκτέλεση αυτών από
            μέρους των Καταστημάτων. Η Εταιρεία δεν φέρει καμία ευθύνη για τυχόν
            ανεπαρκή ποιότητα των προϊόντων ή των υπηρεσιών που προσφέρονται από
            τα Καταστήματα ούτε για την ποιότητα, συντήρηση, αποθήκευση και
            έλεγχο των τροφίμων και προϊόντων που εμφανίζονται στην Ιστοσελίδα
            και πωλούνται από τα Καταστήματα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Τα Καταστήματα είναι υπεύθυνα για την έκδοση από τις αρμόδιες αρχές
            και διατήρηση σε ισχύ όλων των αδειών ή εγκρίσεων ή πιστοποιήσεων
            που απαιτούνται κατά νόμο για τη λειτουργία τους, καθώς και για τη
            διασφάλιση ότι τα πωλούμενα προϊόντα τους είναι νόμιμα και τηρούν
            όλες τις προϋποθέσεις του νόμου σχετικά με αυτά.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Τα Καταστήματα είναι αποκλειστικά υπεύθυνα για την προετοιμασία, την
            κατάσταση και την ποιότητα των εμπορευμάτων και για την εκτέλεση και
            παράδοση των παραγγελιών σας στις περιπτώσεις που η παράδοση των
            παραγγελιών γίνεται από αυτές. Η Εταιρεία δεν φέρει καμία ευθύνη για
            οποιαδήποτε απώλεια ή ζημία προκύψει από τη συμβατική σας σχέση με
            τα Καταστήματα, τα στοιχεία των οποίων αναγράφονται στην Ιστοσελίδας
            και στις αποδείξεις λιανικής πώλησης που λαμβάνετε κατά την
            παράδοση/εκτέλεση των παραγγελιών σας από τα Καταστήματα. Τυχόν
            δικαιώματά σας που απορρέουν από την σχέση σας με τα Καταστήματα
            (όπως το δικαίωμα υπαναχώρησης) μπορούν να υποβληθούν μόνο στα
            Καταστήματα και όχι στην Εταιρεία.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία καταβάλλει κάθε δυνατή προσπάθεια προκειμένου να
            εξασφαλίσει ότι το περιεχόμενο της Ιστοσελίδας θα περιλαμβάνει
            πλήρεις, ακριβείς, σαφείς, έγκυρες, επίκαιρες, αληθείς και μη
            παραπλανητικές πληροφορίες. Όμως η Εταιρεία δεν παρέχει καμία
            δέσμευση και δεν φέρει καμία ευθύνη για την ασφάλεια της Ιστοσελίδας
            και του Υλικού ούτε για το υλικό που εισφέρεται από τρίτους,
            συμπεριλαμβανομένων των αξιολογήσεων Καταστημάτων. Η Εταιρεία δεν
            εγγυάται ότι η Ιστοσελίδα και/ή το Υλικό, συμπεριλαμβανομένου του
            περιεχομένου που εισφέρεται από χρήστες (όπως οι αξιολογήσεις) δεν
            θα προσβάλλει δικαιώματα τρίτων. Κάνοντας χρήση της Ιστοσελίδας
            αποδέχεστε την πιθανότητα αδυναμίας ελέγχου εκ μέρους της Εταιρείας
            του συνόλου του περιεχομένου της Ιστοσελίδας και του Υλικού.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Το περιεχόμενο της Ιστοσελίδας δεν αποτελεί και δεν δύναται σε καμία
            περίπτωση να ερμηνευθεί ως παροχή συμβουλής, άμεσης ή έμμεσης
            προτροπής των χρηστών να προβούν σε οποιαδήποτε πράξη ή ενέργεια. Η
            αξιολόγηση του περιεχομένου εναπόκειται σε κάθε χρήστη ο οποίος
            αναλαμβάνει και την ευθύνη για τη χρήση οποιουδήποτε τμήματός της.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Επισημαίνεται στην Ιστοσελίδα υπάρχουν «σύνδεσμοι» (hyperlinks ή
            banners) προς άλλους διαδικτυακούς τόπους, των οποίων τη διαχείριση
            δεν έχει η Εταιρεία και δεν ασκεί κανένα έλεγχο επ’ αυτών, των εκ
            μέρους τους παρεχομένων υπηρεσιών και της πολιτικής προστασίας
            προσωπικών δεδομένων που τηρείται εκ μέρους τους. Η χρήση των
            ανωτέρω συνδέσμων υπόκειται αποκλειστικά στους δικούς τους όρους
            χρήσης, για τους οποίους οφείλετε να ενημερώνεστε. Η Εταιρεία δεν
            φέρει καμία απολύτως ευθύνη για τυχόν απώλειες ή ζημίες που
            ενδέχεται να προκύψουν στους χρήστες από την χρήση των συνδέσμων. Η
            σύνδεση με τους ανωτέρω συνδέσμους πραγματοποιείται με ατομική
            ευθύνη του κάθε χρήστη.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Οι χρήστες είναι αποκλειστικά υπεύθυνοι για την κατοχή και συντήρηση
            των τηλεφωνικών συνδέσεων, υπολογιστών και του εν γένει εξοπλισμού ο
            οποίος είναι απαραίτητος για την χρήση των υπηρεσιών της Εταιρείας
            και την πρόσβαση στην Ιστοσελίδα. Οι χρήστες καλούνται να
            χρησιμοποιούν λογισμικό προστασίας από ηλεκτρονικούς ιούς και άλλου
            είδους κακόβουλο λογισμικό.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία δεν φέρει ευθύνη για ζημίες και έξοδα τα οποία προκύπτουν
            σε σχέση με τη χρήση της Ιστοσελίδας ή την αδυναμία χρήσης της, από
            οποιοδήποτε πρόσωπο, ή σε σχέση με αδυναμία εκτέλεσης, λάθος,
            παράλειψη, διακοπή, ελάττωμα, καθυστέρηση λειτουργίας ή εκπομπής ή
            πτώση γραμμής συστήματος. Σε περίπτωση κατά την οποία προκληθεί
            ζημία, ή βλάβη, ή μόλυνση από ηλεκτρονικούς ιούς στον ηλεκτρονικό
            υπολογιστή ή σε άλλο ηλεκτρονικό μέσο που χρησιμοποιηθεί από χρήστη
            για την πρόσβαση / επίσκεψη / χρήση ή για την λήψη οποιουδήποτε
            υλικού, δεδομένων, κειμένου, εικόνων, βίντεο, ή ήχου του
            περιεχομένου του, η Εταιρεία δεν φέρει καμία απολύτως ευθύνη.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Οι υπηρεσίες που διατίθενται μέσω της Ιστοσελίδας, παρέχονται στους
            χρήστες "ως έχουν". Η Εταιρεία δεν εγγυάται ότι το περιεχόμενο της
            Ιστοσελίδας και η ποιότητα των παρεχόμενων υπηρεσιών θα
            ανταποκρίνεται στις απαιτήσεις και τις προσδοκίες των χρηστών. Η
            Εταιρεία δεν φέρει οποιαδήποτε ευθύνη για τυχόν απαιτήσεις νομικής ή
            αστικής ή ποινικής φύσεως σχετικές με τη λειτουργία ή τη χρήση της
            Ιστοσελίδας και/ή του Υλικού, ούτε για τυχόν ζημία, οποιουδήποτε
            είδους, η οποία προκλήθηκε είτε από επισκέπτες του διαδικτυακού
            τόπου είτε από τρίτους.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία, οι υπάλληλοι, οι εργαζόμενοι, οι συνεργάτες και οι
            προστηθέντες της, στο βαθμό που επιτρέπεται από την εφαρμοστέα
            νομοθεσία, αποποιούνται κάθε ευθύνη για άμεσες, έμμεσες, θετικές ή
            αποθετικές ζημίες, έξοδα και/ή χρηματικές ποινές,
            συμπεριλαμβανομένων, ενδεικτικά των διαφυγόντων κερδών, διακοπής
            λειτουργίας της Εταιρείας ή κάποιου Καταστήματος, της απώλειας
            πληροφοριών, δεδομένων, πελατείας, της απώλειας ή ζημίας περιουσίας,
            και οποιωνδήποτε αξιώσεων τρίτων μερών προκύψουν από ή σε σχέση με
            την χρήση, την αντιγραφή, ή την παρουσίαση της Ιστοσελίδας και/ή του
            Υλικού ή οποιασδήποτε άλλης συνδεδεμένης ιστοσελίδας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαιώματα Πνευματικής Ιδιοκτησίας</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Ο σχεδιασμός της Ιστοσελίδας, το Υλικό, το κείμενο, τα γραφικά η
            επιλογή και οι ρυθμίσεις αυτής, η σύνθεση του περιεχομένου και η
            διάταξη των στοιχείων που κάθε φορά περιέχονται στην Ιστοσελίδα
            αποτελούν πνευματική και βιομηχανική ιδιοκτησία της Εταιρείας και
            ανήκουν στην Εταιρεία.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία και οι προμηθευτές ή/ και δικαιοπάροχοί της ρητά δια του
            παρόντος επιφυλάσσονται για όλα τα δικαιώματα πνευματικής και
            βιομηχανικής ιδιοκτησίας σε κάθε και όλα τα στοιχεία του
            περιεχομένου της Ιστοσελίδας ή σε άλλα υλικά που εμφανίζονται στην
            Ιστοσελίδα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Ο χρήστης δεσμεύεται να μην δημιουργήσει παράγωγο και βασισμένο στο
            περιεχόμενο της Ιστοσελίδας έργο, να μην αντιγράψει, αναπαραγάγει,
            επαναδημοσιεύσει, «ανεβάσει», τροποποιήσει, μεταδώσει ή πωλήσει ή
            εκμεταλλευτεί με οποιοδήποτε τρόπο εν όλω ή εν μέρει το Υλικό ή το
            σχετικό με την Ιστοσελίδα λογισμικό, να μην την εισάγει σε
            ευρετήριο, να μην την πλαισιώσει με άλλη ιστοσελίδα, να μην την
            εισάγει ως σύνδεσμο για απευθείας πρόσβαση από άλλο ιστότοπο σε
            εσωτερικές πληροφορίες της, να μην αντιγράψει την τοποθεσία της σε
            άλλο διακομιστή με τη δημιουργία ειδώλου και να μην υποσχεθεί
            δικαίωμα σε τρίτο από της Ιστοσελίδας χωρίς τη ρητή προηγούμενη
            έγγραφη συναίνεση της Εταιρείας.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Δεν επιτρέπεται επίσης η δημιουργία ή/ και η δημοσίευση βάσης
            δεδομένων που περιέχει σημαντικά τμήματα της Ιστοσελίδας χωρίς την
            προηγούμενη ρητή συγκατάθεση της Εταιρείας ούτε η χρήση της
            Ιστοσελίδας για την μετάδοση περιεχομένου που παραβιάζει οποιαδήποτε
            δικαιώματα πνευματικής και βιομηχανικής ιδιοκτησίας τρίτων ή της
            Εταιρείας.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία σας χορηγεί ένα μη αποκλειστικό, αμεταβίβαστο, προσωπικό,
            περιορισμένο δικαίωμα πρόσβασης και χρήσης της Ιστοσελίδας και του
            Υλικού, υπό τους ακόλουθους όρους: (1) πρέπει να διατηρείτε σε όλα
            τα αντίγραφα της Ιστοσελίδας και του Υλικού, όλες τις επισημειώσεις
            που αφορούν σε πνευματικά και άλλα ιδιοκτησιακά δικαιώματα, (2) δεν
            μπορείτε να τροποποιήσετε την Ιστοσελίδα και/ή το Υλικό με κανένα
            τρόπο ή να αναπαράγετε ή να παρουσιάσετε δημοσίως, ή να διανείμετε ή
            με άλλο τρόπο να χρησιμοποιήσετε την Ιστοσελίδα και/ή το Υλικό για
            οποιοδήποτε δημόσιο ή εμπορικό σκοπό, εκτός εάν άλλως προβλέπεται
            στους παρόντες Όρους Χρήσης.
          </h4>
        </div>
        <div class="info-title">
          <h4>
            Διαφημιστικά ενημερωτικά μηνύματα - Παροχή άδειας για διαφημιστικους
            λογους
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Ο χρήστης που ενδιαφέρεται να διαφημιστεί στην Ιστοσελίδα, θα πρέπει
            να αποστείλει σχετική αίτηση και να λάβει ρητή άδεια από την
            Εταιρεία. Δεν επιτρέπεται η χρήση των υπηρεσιών της Ιστοσελίδας για
            εμπορική άγρα πελατών από χρήστες της Ιστοσελίδας για χρήση άλλων
            υπηρεσιών χωρίς να έχει προηγηθεί ειδική άδεια από την Εταιρεία.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Ο χρήστης που αναρτά ή/και δημοσιεύει πληροφορίες, δεδομένα,
            κείμενα, γραφικά, φωτογραφίες, εικόνες, μουσικά αρχεία, βίντεο,
            μηνύματα συναινεί στην ανάρτηση ή/και δημοσίευση διαφημίσεων από την
            Εταιρεία στην Ιστοσελίδα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία δεν ευθύνεται για την πολιτική προστασίας των προσωπικών
            δεδομένων που ακολουθούν οι διαφημιζόμενοι στην Ιστοσελίδα και/ή τα
            Καταστήματα.
          </h4>
        </div>
        <div class="info-title">
          <h4>Εφαρμοστέο Δίκαιο και δικαιοδοσία</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Οι παρόντες Όροι Χρήσης και κάθε τροποποίησή τους διέπονται από το
            Ελληνικό Δίκαιο. Για κάθε διαφορά που τυχόν προκύψει από την χρήση
            της Ιστοσελίδας και/λη σε σχέση με τους παρόντες Όρους Χρήσης
            αρμόδια ορίζονται τα καθ' ύλη δικαστήρια των Αθηνών. Σύμφωνα με την
            Οδηγία 2013/11/ΕΚ, η οποία ενσωματώθηκε στην Ελλάδα με την ΚΥΑ
            70330/2015, προβλέπεται πλέον και η δυνατότητα ηλεκτρονικής επίλυσης
            καταναλωτικών διαφορών με τη διαδικασία Εναλλακτικής Επίλυσης
            Διαφορών (ΕΕΔ - Alternative Dispute Resolution) στο σύνολο της
            Ευρωπαϊκής Ένωσης, δια μέσου της ενιαίας πανενωσιακής πλατφόρμας για
            την ηλεκτρονική επίλυση διαφορών (πλατφόρμα ΗΕΔ) διαθέσιμη στο
            https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage
          </h4>
        </div>
        <div class="info-title">
          <h4>Προσωπικά δεδομένα</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία συμμορφώνεται με το ισχύον νομοθετικό πλαίσιο σχετικά με
            την προστασία των προσωπικών δεδομένων (και ιδίως με το Γενικό
            Κανονισμό Προστασίας Προσωπικών Δεδομένων, τον ν. 4624/2019 και τον
            ν. 3471/2006). Περισσότερες πληροφορίες θα βρείτε στην Πολιτική
            Προστασίας Προσωπικών Δεδομένων.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>Τελευταία ενημέρωση: 14/09/2023</strong>
          </h4>
        </div>
      </div>
      <div
        id="privacy-policy-container"
        v-else-if="$router.currentRoute.params.type == 'privacy-policy'"
      >
        <div class="info-type">
          <h4>Πολιτική Προστασίας Προσωπικών Δεδομένων</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Στην Εταιρεία ενδιαφερόμαστε για την προστασία των προσωπικών σας
            δεδομένων και θέλουμε να είμαστε ανοιχτοί και διαφανείς σχετικά με
            τον τρόπο επεξεργασίας τους.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Για το λόγο αυτό έχουμε δημιουργήσει την παρούσα Πολιτική Προστασίας
            Προσωπικών Δεδομένων στην οποία εξηγούμε πώς συλλέγουμε μέσω της
            ιστοσελίδας μας {{ isApplication ? "" : "Fluter.gr"}} (η «Ιστοσελίδα») και επεξεργαζόμαστε και
            προστατεύουμε τα προσωπικά σας δεδομένα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η επεξεργασία των προσωπικών δεδομένων γίνεται σύμφωνα με τις
            διατάξεις του Γενικού Κανονισμού Προστασίας Προσωπικών Δεδομένων
            (ΓΚΠΔ 2016/679), τυχόν ειδικότερης εθνικής και ευρωπαϊκής νομοθεσίας
            για ορισμένους τομείς, της εκάστοτε ισχύουσας ελληνικής νομοθεσίας
            για την προστασία δεδομένων προσωπικού χαρακτήρα (Ν. 4624/2019),
            καθώς και για την προστασία δεδομένων προσωπικού χαρακτήρα και της
            ιδιωτικής ζωής στον τομέα των ηλεκτρονικών επικοινωνιών (Ν.
            3471/2006, ως εκάστοτε ισχύει) και τις αποφάσεις της Αρχής
            Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (ΑΠΔΠΧ)
          </h4>
        </div>
        <div class="info-title">
          <h4>Υπεύθυνη Επεξεργασίας</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η ελληνική εταιρεία DEVINO ΕE (στο εξής η «Εταιρεία») είναι η
            υπεύθυνη επεξεργασίας των προσωπικών σας δεδομένων σύμφωνα με την
            ισχύουσα νομοθεσία.
            <br />
            <br />
            <strong>Επωνυμία:</strong> DEVINO EE
            <br />
            <br />
            <strong>Διεύθυνση:</strong> ΥΨΗΛΑΝΤΟΥ 8 ΚΑΛΥΒΙΑ ΘΟΡΙΚΟΥ, Νομός
            ΑΤΤΙΚΗΣ
            <br />
            <br />
            <strong>Αριθμός Γ.Ε.ΜΗ.:</strong> 158997503000
            <br />
            <br />
            <strong>Α.Φ.Μ.:</strong> 801552236
            <br />
            <br />
            <strong>Τηλ.:</strong> +30 2111187687
            <br />
            <br />
            <strong>e-mail επικοινωνίας για θέματα προσωπικών δεδομένων:</strong>
            info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Πού αποθηκεύουμε τα δεδομένα σας;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Εκτός από τις περιπτώσεις που ρητά αναφέρονται στην παρούσα, τα
            δεδομένα που συλλέγουμε από εσάς αποθηκεύονται εντός του Ευρωπαϊκού
            Οικονομικού Χώρου.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τα Δικαιώματά Σας</h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα πρόσβασης:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ζητήσετε πληροφορίες σχετικά με τα προσωπικά
            δεδομένα σας που βρίσκονται στην κατοχή μας ανά πάσα στιγμή. Προς το
            σκοπό αυτό μπορείτε να μας αποστείλετε e-mail στο info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα στην φορητότητα:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Στις περιπτώσεις που η Εταιρεία επεξεργάζεται προσωπικά σας δεδομένα
            με αυτοματοποιημένα μέσα στηριζόμενη στη συγκατάθεσή σας ή βάσει
            σύμβασης, έχετε το δικαίωμα να λάβετε αντίγραφο των δεδομένων σας σε
            δομημένο, κοινώς χρησιμοποιούμενο και αναγνώσιμο από μηχανήματα
            μορφότυπο. Μπορείτε επίσης να ζητήσετε την απευθείας διαβίβαση των
            δεδομένων σας σε τρίτο πρόσωπο. Το ανωτέρω δικαίωμα αφορά μόνο
            προσωπικά δεδομένα που έχετε παράσχει εσείς σε εμάς. Για να ασκήσετε
            το παραπάνω δικαίωμά σας μπορείτε να μας στείλετε e-mail στο
            info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα διόρθωσης:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ζητήσετε τη διόρθωση των προσωπικών σας
            δεδομένων αν είναι ανακριβή ή τη συμπλήρωσή τους αν είναι ελλιπή. Αν
            διατηρείτε λογαριασμό στην Εταιρεία, μπορείτε να τροποποιήσετε μέσω
            αυτού τα δεδομένα σας. Μπορείτε επίσης να μας στείλετε e-mail στο
            info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα διαγραφής:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ζητήσετε ανά πάσα στιγμή τη διαγραφή των
            προσωπικών σας δεδομένων τα οποία επεξεργάζεται η Εταιρεία ιδίως
            όταν:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ζητήσετε ανά πάσα στιγμή τη διαγραφή των
            προσωπικών σας δεδομένων τα οποία επεξεργάζεται η Εταιρεία ιδίως
            όταν:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Τα προσωπικά σας δεδομένα δεν είναι πλέον
            απαραίτητα σε σχέση με τους σκοπούς για τους οποίους συλλέχθηκαν ή
            υποβλήθηκαν κατ’ άλλο τρόπο σε επεξεργασία
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η επεξεργασία των δεδομένων σας στηρίζεται στη
            συγκατάθεσή σας και ανακαλείτε τη συγκατάθεση αυτή
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Τα δεδομένα σας υποβλήθηκαν σε επεξεργασία
            παράνομα
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>Το ανωτέρω δικαίωμα δεν υφίσταται ιδίως όταν:</h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η επεξεργασία των δεδομένων σας είναι απαραίτητη
            για τη θεμελίωση, άσκηση ή υποστήριξη νομικών αξιώσεων
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Η επεξεργασία των δεδομένων σας είναι απαραίτητη
            για την τήρηση νομικής υποχρέωσης της Εταιρείας που επιβάλλει την
            επεξεργασία.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Σε κάθε περίπτωση, θα σας ενημερώσουμε για την ικανοποίηση ή μη του
            αιτήματός σας και σε περίπτωση μη ικανοποίησης, για τους λόγους
            στους οποίους οφείλεται αυτή. Για να ασκήσετε το παραπάνω δικαίωμά
            σας μπορείτε να μας στείλετε e-mail στο info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα εναντίωσης:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να αντιταχθείτε στην επεξεργασία των προσωπικών
            σας δεδομένων που στηρίζεται σε έννομο συμφέρον της Εταιρείας. Στην
            περίπτωση αυτή θα σταματήσουμε να επεξεργαζόμαστε τα προσωπικά σας
            δεδομένα, εκτός αν μπορούμε να αποδείξουμε ότι έχουμε επιτακτικούς
            και νόμιμους λόγους για την επεξεργασία οι οποίοι υπερισχύουν των
            δικαιωμάτων και των ελευθεριών σας ή για τη θεμελίωση, άσκηση ή
            υποστήριξη νομικών αξιώσεων. Για να ασκήσετε το παραπάνω δικαίωμά
            σας μπορείτε να μας στείλετε e-mail στο info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα εναντίωσης στην απευθείας εμπορική προώθηση:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να αντιταχθείτε στην επεξεργασία των προσωπικών
            σας δεδομένων για σκοπούς απευθείας εμπορικής προώθησης.
            <br />Μπορείτε να δηλώσετε τη σχετική επιθυμία σας με τους ακόλουθους
            τρόπους:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Ακολουθώντας τις οδηγίες που συνοδεύουν κάθε
            e-mail με προωθητικό περιεχόμενο
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Αποστέλλοντας e-mail στο info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα περιορισμού:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ζητήσετε τον περιορισμό της επεξεργασίας των
            προσωπικών σας δεδομένων στις ακόλουθες περιπτώσεις:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Όταν αμφισβητείτε την ακρίβεια των προσωπικών σας
            δεδομένων και μέχρι η Εταιρεία να επαληθεύσει την ακρίβεια αυτών.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Όταν η επεξεργασία των δεδομένων σας είναι
            παράνομη και αντιτάσσεστε στη διαγραφή τους ζητώντας αντ’ αυτής τον
            περιορισμό της χρήσης τους.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Όταν η Εταιρεία δεν χρειάζεται πλέον τα προσωπικά
            σας δεδομένα για τους σκοπούς της επεξεργασίας, αλλά τα δεδομένα
            αυτά σας είναι απαραίτητα για την θεμελίωση, άσκηση ή υποστήριξη
            νομικών αξιώσεων
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Όταν αντιτάσσετε στην επεξεργασία των δεδομένων
            σας από την Εταιρεία που στηρίζεται σε έννομο συμφέρον της
            τελευταίας και μέχρι να επαληθευθεί ή ύπαρξη οι μη νόμιμων λόγων της
            Εταιρείας που υπερισχύουν των ελευθεριών ή δικαιωμάτων σας.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Για να ασκήσετε το παραπάνω δικαίωμά σας μπορείτε να μας στείλετε
            e-mail στο info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα υποβολής καταγγελίας στην αρμόδια εποπτική αρχή:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Αν θεωρείτε ότι η Εταιρεία επεξεργάζεται τα δεδομένα σας με
            εσφαλμένο τρόπο, μπορείτε να επικοινωνήσετε μαζί μας στο
            info@fluter.gr ή τηλεφωνικά στο +30 698 674 9306 & +30 695 729 1940
            Μπορείτε επίσης να υποβάλετε καταγγελία στην Αρχή Προστασίας
            Δεδομένων Προσωπικού Χαρακτήρα ή στην τυχόν άλλη αρμόδια εποπτική
            αρχή
          </h4>
        </div>
        <div class="info-title">
          <h4>Τροποποιήσεις της Πολιτικής Απορρήτου μας:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Μπορεί να χρειαστεί να τροποποιήσουμε την Πολιτική Προστασίας
            Προσωπικών Δεδομένων. Η τελευταία έκδοση είναι πάντα διαθέσιμη στην
            Ιστοσελίδα.
          </h4>
        </div>
        <div class="info-title">
          <h4>ΔΙΑΔΙΚΤΥΑΚΕΣ ΠΑΡΑΓΓΕΛΙΕΣ</h4>
        </div>
        <div class="info-title">
          <h4>Γιατί χρησιμοποιούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τα προσωπικά σας δεδομένα για να διαχειριζόμαστε
            τις διαδικτυακές παραγγελίες στις οποίες προβαίνετε μέσω της
            Ιστοσελίδας, να διαβιβάζουμε στα συνεργαζόμενα καταστήματα (τα
            «Καταστήματα») προσωπικές πληροφορίες (όπως πληροφορίες για
            αλλεργίες σας) τις οποίες επιλέγετε να συμπεριλάβετε στις
            παραγγελίες σας. Αποθηκεύουμε επίσης στοιχεία της κάρτας σας σε
            περίπτωση που επιλέξετε να αποθηκεύσετε την κάρτα σας στην
            Ιστοσελίδα ώστε να μπορείτε να πληρώνετε μέσω αυτής τις παραγγελίες
            σας, για να εκτελεστεί η πληρωμή.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τι είδους προσωπικά δεδομένα επεξεργαζόμαστε;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Επεξεργαζόμαστε τις ακόλουθες κατηγορίες προσωπικών δεδομένων που
            σας αφορούν:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            e-mail, κωδικός πρόσβασης, τηλέφωνο επικοινωνίας, όνομα (έφοσον
            επιλέξετε να το μοιραστείτε μαζί μας), τα 4 τελευταία στοιχεία της
            κάρτας σας, την ημερομηνία λήξης αυτής και το ονοματεπώνυμο που
            αναγράφεται στην κάρτα σε κρυπτογραφημένη μορφή (εφόσον επιλέξετε να
            αποθηκεύσετε την κάρτα σας στην Ιστοσελίδα ώστε να μπορείτε να
            πληρώνετε μέσω αυτής τις παραγγελίες σας), τυχόν προσωπικά δεδομένα
            που εσείς έχετε επιλέξει να συμπεριλάβετε στην παραγγελία σας υπό
            την μορφή σχολιών
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποιος έχει πρόσβαση στα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Μετά την καταχώρηση της παραγγελίας σας η παραγγελία σας,
            διαβιβάζονται στο Κατάστημα μόνο τα τυχόν προσωπικά δεδομένα που
            εσείς έχετε επιλέξει να συμπεριλάβετε στην παραγγελία σας υπό την
            μορφή σχολιών (όπως πχ πληροφορίες για τυχόν αλλεργίες σας)
            προκειμένου το τελευταίο να εκτελέσει την παραγγελία. Το Κατάστημα
            ενεργεί ως ανεξάρτητος υπεύθυνος επεξεργασίας και προβαίνει σε
            επεξεργασίας των προσωπικών σας δεδομένων σύμφωνα με την πολιτική
            προστασίας προσωπικών δεδομένων που εφαρμόζει. Η Εταιρεία μας δεν
            φέρει καμία ευθύνη για την επεξεργασία που διενεργείται από το
            Κατάστημα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Επίσης, σε περίπτωση που προβείτε σε εξόφληση της παραγγελίας μέσω
            της Ιστοσελίδας με την χρήση κάρτας, μεταφέρεστε μέσω της
            Ιστοσελίδας σε ασφαλές περιβάλλον του συνεργαζόμενου με την Εταιρεία
            ιδρύματος πληρωμών Everypay, το οποίο συλλέγει τα στοιχεία της
            κάρτας σας που είναι αναγκαία για την εκτέλεση της πληρωμής.
            Επιπροσθέτως, στην περίπτωση αυτή αποστέλλουμε στην Everypay το
            τηλέφωνο και το κινητό σας, τα οποία είναι αναγκαία για την εκ
            μέρους της Everypay παροχή των υπηρεσιών της. Η Everypay
            επεξεργάζεται τα προσωπικά σας δεδομένα ως ανεξάρτητος υπεύθυνος
            επεξεργασίας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποια είναι η νομική βάση επεξεργασίας των προσωπικών σας δεδομένων;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η επεξεργασία των προσωπικών σας δεδομένων η σύμβαση που συνάπτεται
            μεταξύ μας όταν καταχωρείτε την παραγγελία σας στην Ιστοσελίδα και
            είναι απαραίτητη για την εκ μέρους της Εταιρείας παροχή της
            υπηρεσίας που συνίσταται στην καταχώρηση της παραγγελίας σας και την
            διαβίβαση αυτής στο Κατάστημα Η αποθήκευση της κάρτας σας για
            πραγματοποίηση πληρωμής μέσω της Ιστοσελίδας στηρίζεται στην
            συγκατάθεσή σας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Για πόσο διάστημα αποθηκεύουμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα διατηρούμε τα προσωπικά σας δεδομένα για όσο χρόνο διατηρείτε
            λογαριασμό στην Ιστοσελίδα. Σε περίπτωση που ο λογαριασμός είναι
            ανενεργός για περισσότερο από τρία χρόνια, ο λογαριασμός σας θα
            διαγράφεται. Τα στοιχεία της κάρτας σας θα τα αποθηκεύουμε για όσο
            χρόνο έχουμε την συγκατάθεσή σας, την οποία μπορείτε να ανακαλέσετε
            οποτεδήποτε. Η ανάκληση ενεργεί για το μέλλον.
          </h4>
        </div>
        <div class="info-title">
          <h4>ΑΠΕΥΘΕΙΑΣ ΕΜΠΟΡΙΚΗ ΠΡΟΩΘΗΣΗ</h4>
        </div>
        <div class="info-title">
          <h4>Γιατί χρησιμοποιούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τα προσωπικά σας δεδομένα για να σας αποστέλλουμε
            newletters και διαφημιστικές προσφορές μέσω e-mail και/ή τηλεφώνου.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Για να κάνουμε καλύτερη την εμπειρία σας ως χρήση, θα σας παρέχουμε
            συναφείς με τα ενδιαφέροντά σας πληροφορίες, θα σας προτείνουμε
            προϊόντα, θα σας αποστέλλουμε υπενθυμίσεις σχετικά με τα προϊόντα
            που υπάρχουν στο καλάθι αγορών σας και προσφορές.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τι είδους δεδομένα επεξεργαζόμαστε;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τις ακόλουθες πληροφορίες προσωπικών δεδομένων:
            στοιχεία επικοινωνίας, όπως e-mail και τηλέφωνο
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποιος έχει πρόσβαση στα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Τα προσωπικά σας δεδομένα που κοινοποιούνται σε τρίτα πρόσωπα,
            χρησιμοποιούνται μόνο για την παροχή σε εσάς των παραπάνω υπηρεσιών.
            Κατά συνέπεια αποδέκτες προσωπικών σας δεδομένων μπορεί να είναι
            διαφημιστικά πρακτορεία και τεχνικοί πάροχοι διάδοσης μηνυμάτων
            εμπορικής προώθησης.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Σε καμία περίπτωση δεν μεταβιβάζουμε, πουλάμε ή ανταλλάσουμε
            προσωπικά σας δεδομένα για σκοπούς εμπορικής προώθησης τρίτων
            προσώπων.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποια είναι η νομική βάση επεξεργασίας των προσωπικών σας δεδομένων;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η επεξεργασία των προσωπικών σας δεδομένων στηρίζεται στη
            συγκατάθεσή σας ή σε περίπτωση που έχει προηγηθεί η εκ μέρους αγορά
            προϊόντων μέσω της Ιστοσελίδας, σε έννομο συμφέρον μας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα ανάκλησης συγκατάθεσης σας:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ανακαλέσετε οποτεδήποτε τη συγκατάθεσή σας,
            αποστέλλοντας e-mail στο info@fluter.gr, και το δικαίωμα να
            αντιταχθείτε στη χρήση των προσωπικών σας δεδομένων για σκοπούς
            απευθείας εμπορικής προώθησης.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Αν ασκήσετε τα δικαιώματα αυτά, η Εταιρεία δε θα είναι πλέον σε θέση
            να σας αποστέλλει διαφημιστικές προσφορές ή πληροφορίες στηριζόμενη
            στη συγκατάθεσή σας.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Μπορείτε να αντιταχθείτε στη χρήση των προσωπικών σας δεδομένων για
            σκοπούς απευθείας εμπορικής προώθησης με τους ακόλουθους τρόπους:
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Ακολουθώντας τις οδηγίες που συνοδεύουν κάθε
            e-mail με προωθητικό περιεχόμενο
          </h4>
        </div>
        <div class="info-paragraph">
          <h4 style="padding-left: 4em">
            <strong>-</strong> Αποστέλλοντας e-mail στο info@fluter.gr
          </h4>
        </div>
        <div class="info-title">
          <h4>Για πόσο διάστημα αποθηκεύουμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα διατηρούμε τα προσωπικά σας δεδομένα για σκοπούς απευθείας
            εμπορικής προώθησης μέχρι να ανακαλέσετε τη συγκατάθεσή σας ή να
            αντιταχθείτε στη χρήση των προσωπικών σας δεδομένων για σκοπούς
            απευθείας εμπορικής προώθησης.
          </h4>
        </div>
        <div class="info-title">
          <h4>ΛΟΓΑΡΙΑΣΜΟΣ ΧΡΗΣΤΗ</h4>
        </div>
        <div class="info-title">
          <h4>Γιατί χρησιμοποιούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τα προσωπικά σας δεδομένα για να διαχειριστούμε
            τον προσωπικό σας λογαριασμό, καθώς και για να επαληθεύσουμε τον
            αριθμό του κινητού σας τηλεφώνου, το οποίο σας ζητείται να
            καταχωρήσετε κατά την δημιουργία λογαριασμού.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα σας παρέχουμε ιστορικό και πληροφορίες σχετικά με τις παραγγελίες
            και σας επιτρέπουμε να διαχειρίζεστε τις ρυθμίσεις του λογαριασμού
            σας. Θα σας παρέχουμε επίσης εύκολους τρόπους ώστε να διατηρείτε
            ακριβή και επίκαιρα τα στοιχεία σας, όπως για παράδειγμα τα στοιχεία
            επικοινωνίας σας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τι είδους προσωπικά δεδομένα συλλέγουμε;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε πάντα το e-mail σας και τον κωδικό πρόσβασης που
            έχετε ορίσει όταν εισέρχεστε στον λογαριασμό που έχετε στην
            Εταιρεία.
            <br />
            <br />Θα επεξεργαζόμαστε επίσης τις ακόλουθες κατηγορίες προσωπικών
            δεδομένων:
            <br />στοιχεία επικοινωνίας, όπως ονοματεπώνυμο και e-mail, τηλέφωνο
            <br />
            <br />Θα επεξεργαζόμαστε τα ακόλουθα δεδομένα σε περίπτωση που προβείτε σε
            καταχώρηση παραγγελίας:
            <br />ιστορικό παραγγελιών
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποιος έχει πρόσβαση στα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Τα προσωπικά σας δεδομένα αποστέλλονται σε τρίτα πρόσωπα μόνο στο
            βαθμό που απαιτείται για την παροχή των ανωτέρω αναφερόμενων
            υπηρεσιών και προς το σκοπό αυτό.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποια είναι η νομική βάση επεξεργασίας των προσωπικών σας δεδομένων;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η επεξεργασία των προσωπικών σας δεδομένων αναφορικά με το
            λογαριασμό σας θεμελιώνεται στη συγκατάθεσή σας με τη δημιουργία του
            λογαριασμού.
          </h4>
        </div>
        <div class="info-title">
          <h4>Δικαίωμα ανάκλησης συγκατάθεσης σας:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ανακαλέσετε οποτεδήποτε τη συγκατάθεσή σας,
            αποστέλλοντας e-mail στο info@fluter.gr. Στην περίπτωση αυτή θα
            προβούμε σε διαγραφή του λογαριασμού σας και δεν μπορείτε να
            προχωρήσετε στην καταχώρηση νέων παραγγελιών μέσω της Ιστοσελίδας
            (εκτός αν δημιουργήσετε ξανά λογαριασμό).
          </h4>
        </div>
        <div class="info-title">
          <h4>Για πόσο διάστημα αποθηκεύουμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα διατηρούμε τα προσωπικά σας δεδομένα για όσο χρόνο έχετε ενεργό
            λογαριασμό. Διατηρείτε το δικαίωμα να καταργήσετε το λογαριασμό σας
            οποιαδήποτε στιγμή. Στην περίπτωση αυτή θα διατηρήσουμε τα προσωπικά
            σας δεδομένα μόνο αν η διατήρηση τους επιβάλλεται από την ισχύουσα
            νομοθεσία και/ή αν υφίσταται κάποια εκκρεμής διένεξη.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Ο λογαριασμός σας θα πάψει να θεωρείται ενεργός αν δεν έχει έχετε
            κάνει καμία παραγγελία για 3 χρόνια. Εφόσον ο λογαριασμός σας
            καταστεί ανενεργός, τα προσωπικά σας δεδομένα θα διαγραφούν.
          </h4>
        </div>
        <div class="info-title">
          <h4>ΕΞΥΠΗΡΕΤΗΣΗ ΠΕΛΑΤΩΝ</h4>
        </div>
        <div class="info-title">
          <h4>Γιατί χρησιμοποιούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τα προσωπικά σας δεδομένα για να διαχειριστούμε
            ερωτήματα, παράπονα, απορίες, ζητήματα σχετικά με παραγγελίες και
            θέματα τεχνικής υποστήριξης.
            <br />Επίσης μπορεί να επικοινωνήσουμε μαζί σας σε περίπτωση που υπάρχει
            κάποιο πρόβλημα με την παραγγελία σας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τι είδους προσωπικά δεδομένα επεξεργαζόμαστε;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαστούμε τα δεδομένα που θέτετε στη διάθεσή σας,
            συμπεριλαμβανομένων των ακόλουθων κατηγοριών:
            <br />στοιχεία επικοινωνίας όπως e-mail και τηλέφωνο
            <br />πληροφορίες σχετικά με παραγγελίες
            <br />στοιχεία λογαριασμού
            <br />κάθε είδους επικοινωνία σχετικά με το ζήτημα
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποιος έχει πρόσβαση στα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Τα προσωπικά σας δεδομένα μπορεί να αποσταλούν στα καταστήματα, μόνο
            στο βαθμό που απαιτείται για την ικανοποίηση των αιτημάτων σας ή την
            διαχείριση των παραπόνων σας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποια είναι η νομική βάση επεξεργασίας των προσωπικών σας δεδομένων;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η επεξεργασία των προσωπικών σας δεδομένων όταν έχει προηγηθεί η εκ
            μέρους σας παραγγελία προϊόντων στηρίζεται στη μεταξύ μας σύμβαση,
            σε κάθε άλλη περίπτωση στηρίζεται σε έννομο συμφέρον μας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Για πόσο χρόνο διατηρούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Διατηρούμε τα προσωπικά σας δεδομένα για διάστημα 3 ετών από την
            οριστική διευθέτηση του αιτήματος, ερωτήματος, παραπόνου, απορίας ή
            άλλου ζητήματος, εκτός αν επιβάλλεται η διατήρηση τους για
            μεγαλύτερο διάστημα προς το σκοπό συμμόρφωσης με έννομες υποχρεώσεις
            μας ή προάσπισης νομίμων δικαιωμάτων μας (ιδίως σε περίπτωση που
            υπάρχει κάποια διένεξη).
          </h4>
        </div>
        <div class="info-title">
          <h4>ΑΞΙΟΛΟΓΗΣΗ ΚΑΤΑΣΤΗΜΑΤΩΝ</h4>
        </div>
        <div class="info-title">
          <h4>Γιατί χρησιμοποιούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τα προσωπικά σας δεδομένα για να καταχωρήσουμε
            την αξιολόγησή σας σχετικά με κάποιο Κατάστημα στην Ιστοσελίδα μας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τι είδους προσωπικά δεδομένα επεξεργαζόμαστε;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τις ακόλουθες κατηγορίες προσωπικών δεδομένων
            <br />
            <br />όνομα (εφόσον επιλέξετε να το μοιραστείτε)
            <br />τυχόν προσωπικά δεδομένα σας που περιέχονται στην αξιολόγησή σας.
            <br />
            <br />Σας συμβουλεύουμε να αποφεύγετε την αναφορά προσωπικών δεδομένων
            στην αξιολόγησή σας. Σημειώνουμε ότι οι αξιολογήσεις θα είναι
            δημόσια προσβάσιμες. Η Εταιρεία δεν φέρει καμία ευθύνη για την χρήση
            εκ μέρους τρίτων προσώπων των προσωπικών δεδομένων που τυχόν
            επιλέγετε να συμπεριλάβετε στην αξιολόγησή σας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποιος έχει πρόσβαση στα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η αξιολόγησή σας θα αναρτάται στην Ιστοσελίδα και θα είναι δημόσια
            προσβάσιμη. Συνεπώς θα μπορούν να λάβουν γνώση των προσωπικών
            δεδομένων σας που περιλαμβάνονται στην αξιολόγησή σας όλοι οι
            χρήστες της Ιστοσελίδας μας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποια είναι η νομική βάση της επεξεργασίας;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η επεξεργασία των προσωπικών σας δεδομένων στηρίζεται στη
            συγκατάθεσή σας, η οποία λαμβάνεται όταν υποβάλλετε την αξιολόγησή
            σας στην Ιστοσελίδα.
          </h4>
        </div>
        <div class="info-title">
          <h4>Το δικαίωμά σας να ανακαλέσετε τη συγκατάθεσή σας:</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Έχετε το δικαίωμα να ανακαλέσετε οποτεδήποτε την παραπάνω
            συγκατάθεσή σας, αποστέλλοντας e-mail στο info@fluter.gr. Στην
            περίπτωση αυτή όμως δε θα είμαστε σε θέση να σας προσφέρουμε τις
            παραπάνω αναφερόμενες υπηρεσίες.
          </h4>
        </div>
        <div class="info-title">
          <h4>Για πόσο χρόνο διατηρούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα διατηρούμε τα προσωπικά σας δεδομένα για 5 χρόνια από την
            ανάρτηση της αξιολόγησης ή μέχρι να διαγραφεί αυτή για οποιοδήποτε
            λόγο.
          </h4>
        </div>
        <div class="info-title">
          <h4>ΕΚΠΛΗΡΩΣΗ ΝΟΜΙΚΩΝ ΥΠΟΧΡΕΩΣΕΩΝ</h4>
        </div>
        <div class="info-title">
          <h4>Γιατί χρησιμοποιούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τα προσωπικά σας δεδομένα για να συμμορφωθούμε με
            έννομες υποχρεώσεις (συμπεριλαμβανομένων των φορολογικών –
            λογιστικών), δικαστικές αποφάσεις και διαταγές δικαστικών ή άλλων
            αρχών.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τι είδους προσωπικά δεδομένα επεξεργαζόμαστε;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε ενδεικτικά τις ακόλουθες κατηγορίες προσωπικών
            δεδομένων
            <br />
            <br />αριθμό παραγγελίας
            <br />ημερομηνία συναλλαγής
            <br />στοιχεία επικοινωνίας
            <br />στοιχεία παραγγελίας
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποιος έχει πρόσβαση στα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα κοινοποιούμε τα προσωπικά σας δεδομένα στους συνεργάτες μας, οι
            οποίοι μας βοηθούν στην εκπλήρωση των ανωτέρω υποχρεώσεών μας, όπως
            οι λογιστές και οι φορολογικοί και οι νομικοί μας σύμβουλοι.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποια είναι η νομική βάση επεξεργασίας των προσωπικών σας δεδομένων;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η ανωτέρω επεξεργασία είναι απαραίτητη για τη συμμόρφωσή μας με
            έννομες υποχρεώσεις μας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Για πόσο χρόνο διατηρούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα διατηρούμε τα προσωπικά σας δεδομένα για όσο χρονικό διάστημα
            απαιτείται από την ισχύουσα νομοθεσία προκειμένου να συμμορφωθούμε
            με τις ανωτέρω υποχρεώσεις μας.
          </h4>
        </div>
        <div class="info-title">
          <h4>ΠΡΟΛΗΨΗ ΚΑΙ ΑΠΟΤΡΟΠΗ ΚΑΚΗΣ ΧΡΗΣΗΣ ΚΑΙ ΕΓΚΛΗΜΑΤΩΝ</h4>
        </div>
        <div class="info-title">
          <h4>Γιατί χρησιμοποιούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαζόμαστε τα προσωπικά σας δεδομένα για τον εντοπισμό, την
            αποτροπή και την αντιμετώπιση περιπτώσεων κακής χρήσης των υπηρεσιών
            μας, καθώς και για την αποτροπή και την έρευνα περιπτώσεων απάτης
            και/ή άλλων εγκληματικών πράξεων.
          </h4>
        </div>
        <div class="info-title">
          <h4>Τι είδους προσωπικά δεδομένα επεξεργαζόμαστε;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα επεξεργαστούμε ενδεικτικά τις ακόλουθες κατηγορίες προσωπικών
            δεδομένων
            <br />
            <br />στοιχεία επικοινωνίας, όπως e-mail και τηλέφωνο ιστορικό και
            <br />πληροφορίες παραγγελιών
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποιος έχει πρόσβαση στα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Μπορεί να κοινοποιήσουμε τα προσωπικά σας δεδομένα σε νομικές αρχές
            και συμβούλους, ανακριτικές ή εισαγγελικές αρχές για τη διενέργεια
            ερευνών. Κάποιοι ή όλοι οι ανωτέρω αποδέκτες ενδέχεται να έχουν
            αυτοτελές δικαίωμα ή υποχρέωση να προβούν στην επεξεργασία των
            προσωπικών σας δεδομένων ενεργώντας στην περίπτωση αυτή ως υπεύθυνοι
            επεξεργασίας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Ποια είναι η νομική βάση επεξεργασίας των προσωπικών σας δεδομένων;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η επεξεργασία των προσωπικών σας δεδομένων προς το σκοπό αποτροπής
            της κατάχρησης των υπηρεσιών μας στηρίζεται στο έννομο συμφέρον μας
            ή – ιδίως σε περιπτώσεις τέλεσης ή υποψίας τέλεσης αξιόποινων
            πράξεων – στην υποχρέωση συμμόρφωσης με έννομες υποχρεώσεις μας.
          </h4>
        </div>
        <div class="info-title">
          <h4>Για πόσο χρόνο διατηρούμε τα προσωπικά σας δεδομένα;</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Θα διατηρούμε τα προσωπικά σας δεδομένα για όσο διάστημα απαιτείται
            προκειμένου να αποτρέψουμε και/ή να αναφέρουμε τελεσθείσες ή πιθανές
            απάτες και άλλα αδικήματα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>Τελευταία ενημέρωση: 14/09/2023</strong>
          </h4>
        </div>
      </div>
      <div
        id="cookies-policy-container"
        v-else-if="$router.currentRoute.params.type == 'cookies-policy'"
      >
        <div class="info-type">
          <h4>Πολιτική Cookies</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Η Εταιρεία χρησιμοποιεί cookies τα οποία είναι αναγκαία για την
            λειτουργία της ιστοσελίδας {{ isApplication ? "" : "fluter.gr"}} (η «Ιστοσελίδα»), τη σωστή
            περιήγηση, τη σύνδεση και τη μετακίνηση στην Ιστοσελίδα.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Μπορείτε να μάθετε περισσότερα για τα cookies και τον τρόπο με τον
            οποίο τα χρησιμοποιούμε παρακάτω. Για περισσότερες πληροφορίες
            σχετικά με την επεξεργασία των προσωπικών σας δεδομένων από την
            Εταιρεία, διαβάστε την Πολιτική Προστασίας Προσωπικών Δεδομένων.
          </h4>
        </div>
        <div class="info-title">
          <h4>Cookies</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Τα cookies είναι μικρά αρχεία κειμένου που περιέχουν πληροφορίες που
            αποθηκεύονται στο web browser του υπολογιστή σας και μπορούν να
            αφαιρεθούν ανά πάσα στιγμή. Μπορούν να μας βοηθήσουν να
            αναγνωρίσουμε τον υπολογιστή σας την επόμενη φορά που θα
            επισκεφθείτε την Ιστοσελίδα ή για να ενεργοποιήσετε κάποιες
            λειτουργίες στις Ιστοσελίδας. Τα cookies κατατάσσονται στις
            ακόλουθες κατηγορίες:
            <br />
            <br />Απολύτως απαραίτητα cookies
            <br />Cookies περιόδου σύνδεσης
            <br />Cookies επιδόσεων
            <br />Cookies στόχευσης / διαφήμισης
            <br />
            <br />Στην Ιστοσελίδα γίνεται χρήση μόνο της πρώτης κατηγορίας cookies.
          </h4>
        </div>
        <div class="info-title">
          <h4>ΑΠΟΛΥΤΩΣ ΑΠΑΡΑΙΤΗΤΑ COOKIES</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Τα αυστηρά απαραίτητα cookies είναι ουσιαστικής σημασίας για την
            ορθή λειτουργία της Ιστοσελίδας. Είναι αυτά που χρησιμοποιούνται
            αποκλειστικά για τη μετάδοση επικοινωνίας σε ένα δίκτυο ηλεκτρονικών
            επικοινωνιών ή, στο μέτρο που αυτό είναι απολύτως απαραίτητο, στον
            πάροχο μιας υπηρεσίας που έχει ζητήσει ρητώς από το συμβαλλόμενο
            μέρος ή τον χρήστη να παράσχει την εν λόγω υπηρεσία. Είναι τεχνικά
            απαραίτητα για την λειτουργία του ιστότοπου γι’ αυτό και δεν έχετε
            την δυνατότητα να τα απορρίψετε.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Διατηρούμε το δικαίωμα να αλλάξουμε ή να ανανεώσουμε αυτή την
            ειδοποίηση για το απόρρητο και τα cookies ανά πάσα στιγμή και όλες
            οι αλλαγές που ενδέχεται να κάνουμε στο μέλλον θα δημοσιεύονται στην
            Ιστοσελίδα. Σας συμβουλεύουμε να ελέγχετε συχνά την παρούσα πολιτική
            για να ενημερώνεστε για τυχόν αλλαγές.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            <strong>Τελευταία ενημέρωση: 14/09/2023</strong>
          </h4>
        </div>
      </div>
      <div
        id="rating-policy-container"
        v-else-if="$router.currentRoute.params.type == 'rating-policy'"
      >
        <div class="info-type">
          <h4>Πολιτική Αξιολόγησης Καταστημάτων</h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Για να αξιολογήσετε κάποιο Κατάστημα, θα πρέπει να έχετε παραγγείλει
            τουλάχιστον μία φορά από το συγκεκριμένο Κατάστημα μέσω της
            Ιστοσελίδας. Για κάθε παραγγελία μπορεί να γίνει μόνο μία
            αξιολόγηση.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Δεν επιτρέπεται να προβαίνουν σε αξιολογήσεις ενός Καταστήματος οι
            ιδιοκτήτες αυτού, μέλη της οικογένειάς τους και /ή πρόσωπα που
            διατηρούν στενούς προσωπικούς, συμπεριλαμβανομένων των φιλικών,
            δεσμούς με αυτούς. Η εν λόγω απαγόρευση αποσκοπεί στην διασφάλιση
            της αντικειμενικότητας και της αξιοπιστίας των αξιολογήσεων. Σε
            περίπτωση που η Εταιρεία διαπιστώσει ότι μία αξιολόγηση παραβιάζει
            τον παρόντα όρο, θα προβαίνει σε άμεση διαγραφή αυτής. Σε περίπτωση
            που διαπιστωθεί επαναλειμμένη παραβίαση του παρόντος όρου, από
            χρήστες ή Καταστήματα, η Εταιρεία δύναται να αναστείλει και/ή να
            διαγράψει τον υπό κρίση λογαριασμό χρήστη, καθώς και να ασκήσει κατά
            του Καταστήματος τα τυχόν δικαιώματα που προβλέπονται στην μεταξύ
            τους σύμβαση.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Οι αξιολογήσεις θα πρέπει να μην περιέχουν υβριστικές ή προσβλητικές
            εκφράσεις ή σχόλια και να σέβονται τα προβλεπόμενα στους Όρους
            Χρήσης αναφορικά με το περιεχόμενο που εισφέρεται στην Ιστοσελίδα
            από χρήστες. Σε διαφορετική περίπτωση και εφόσον η Εταιρεία
            διαπιστώσει ότι μία αξιολόγηση παραβίαζει την παρούσα πολιτική και/ή
            τους Όρους Χρήσης και/ή την κείμενη νομοθεσία, η Εταιρεία θα
            προβαίνει σε διαγραφή της σχετικής αξιολόγησης και εφόσον το κρίνει
            αναγκαίο σε αναστολή και /ή διαγραφή του λογαριασμού του χρήστη που
            ανάρητησε την αξιολόγηση.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Για την ανάρτηση μίας αξιολόγησης, θα πρέπει να τηρείται λογαριασμός
            χρήστη στην Ιστοσελίδα και η αξιολόγηση να γίνει μέσω του
            λογαριασμού αυτού.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Οι αξιολογήσεις αντανακλούν την άποψη των χρηστών που τις αναρτούν.
            Η Εταιρεία δεν φέρει ουδεμία ευθύνη για το περιεχόμενό τους ούτε
            είναι υποχρεωμένη να προβαίνει σε έλεγχο των αξιολογήσεων ώστε να
            διαπιστώνει αν αυτές είναι σύμφωνες με την παρούσα πολιτική και/ή
            τους Όρους Χρήσης.
          </h4>
        </div>
        <div class="info-paragraph">
          <h4>
            Σε περίπτωση που θεωρείτε ότι μία αξιολόγηση παραβιάζει την παρούσα
            πολιτική και/ή τους Όρους Χρήσης, θα πρέπει να επικοινωνήσετε με την
            Εταιρεία.
          </h4>
        </div>
      </div>
      <div v-else-if="$router.currentRoute.params.type === 'about-us'" id="info-text-container">
        <div id="info-text">
          <div class="info-type">
            <h4>Ποιοί είμαστε;</h4>
          </div>
          <div class="info-title">
            <h4>Fluter (Ουσιαστικό): Σημαίνει παραγγελία στη γλώσσα της εστίασης.</h4>
          </div>
          <div class="info-paragraph">
            <h4>
              Τι κοινό έχουν το τραπέζι του ψητοπωλείου, το δωμάτιο του ξενοδοχείου
              και η ξαπλώστρα της καφετέριας; Σε όλα παραγγέλνεις πιο ευχάριστα και
              γρήγορα από ποτέ με το
              <strong>Fluter</strong>!
            </h4>
          </div>
        </div>
      </div>
      <div v-else id="contact-us-form">
        <div id="something-to-say" class="info-type">
          <h4>{{ labels.contactForm.somethingToSay[language] }}</h4>
        </div>

        <div id="fluter-contact-details">
          <div>
            <div class="fluter-contact-header">
              <font-awesome-icon icon="phone"></font-awesome-icon>
              <span>2111187687</span>
            </div>
            <div class="fluter-contact-header">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
              <span>info@fluter.gr</span>
            </div>
          </div>
        </div>
        <div id="name-and-phone">
          <div id="name">
            <h4 id="name-label">{{ labels.contactForm.name[language] }}</h4>
            <input
              v-on:keypress.enter="sendMessage"
              type="text"
              name="text-input"
              id="text-input"
              v-model="name"
            />
          </div>
          <div id="phone">
            <h4
              @keypress.enter="sendMessage"
              id="phone-label"
            >{{ labels.contactForm.phone[language] }}</h4>
            <input
              @keypress.enter="sendMessage"
              type="tel"
              name="phone-input"
              id="phone-input"
              v-model="phone"
            />
          </div>
        </div>
        <div id="email">
          <h4 id="email-label">Email</h4>
          <input
            @keypress.enter="sendMessage"
            type="email"
            name="email-input"
            id="email-input"
            v-model="email"
          />
        </div>
        <div v-if="badEmail" class="error-container">
          <h4 class="error">{{ labels.contactForm.invalidEmail[language] }}</h4>
        </div>
        <div id="message">
          <h4 id="message-label">{{ labels.contactForm.yourMessage[language] }}</h4>
          <textarea name="comment" ref="comment" id="comment" v-model="message" cols="30" rows="10"></textarea>
        </div>
        <Button
          @click="sendMessage"
          :loading="buttonLoading"
          :disabled="buttonLoading || successfullySentMessage"
          :class="{ alreadySent: successfullySentMessage }"
          id="button"
        >{{ labels.contactForm.send[language] }}</Button>
        <div v-if="emptyInputs" class="error-container">
          <h4 class="error">{{ labels.contactForm.mandatoryFields[language] }}</h4>
        </div>
        <div v-if="errorInSendMessage" class="error-container">
          <h4 class="error">{{ sendMessageError[language] }}</h4>
        </div>
        <div v-if="successfullySentMessage" class="success-container">
          <h4 class="success">{{ successfullMessage[language] }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteHeader from "../WhiteHeader";
import Button from "../Button";

import MessageApi from "../../api/message";

export default {
  name: "Info",
  components: {
    WhiteHeader,
    Button,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isEntranceModalOpen) {
      this.closeEntrance();
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    let info = this.$router.currentRoute.params.type;
    this.titles.forEach((title) => {
      if (title.name === info) {
        title.isSelected = true;
        this.selectedTitle = title.name;
      } else {
        title.isSelected = false;
      }
    });
    this.isApplication = this.$route.query.isApplication;
    if (this.isApplication) {
      this.titles = this.titles.filter(title => title.name != 'contact-us')
    }
    this.setTitle();
  },
  computed: {
    name: {
      get() {
        return this.userName;
      },

      set(newName) {
        this.userName = newName;
      },
    },
    email: {
      get() {
        return this.userEmail;
      },

      set(newEmail) {
        this.userEmail = newEmail;
      },
    },
    phone: {
      get() {
        return this.userPhone;
      },

      set(newPhone) {
        this.userPhone = newPhone;
      },
    },
    message: {
      get() {
        return this.userMessage;
      },

      set(newMessage) {
        this.userMessage = newMessage;
      },
    },
    infoType() {
      return this.$router.currentRoute.params.type;
    },
    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },
    isEntranceModalOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },
    language() {
      return this.$store.getters.getLanguage;
    },

    title() {
      return `${this.labels.titles.info[this.language]} | Fluter`;
    },
  },
  data() {
    return {
      userName: "",
      userPhone: "",
      userEmail: "",
      userMessage: "",
      buttonLoading: false,
      badEmail: false,
      emptyInputs: false,
      errorInSendMessage: false,
      sendMessageError: "",
      successfullySentMessage: false,
      successfullMessage: "",
      isApplication: false,
      selectedTitle: "about-us",
      titles: [
        {
          id: 1,
          title: "termsOfService",
          isSelected: false,
          name: "terms-of-service",
        },
        {
          id: 2,
          title: "privacyPolicy",
          isSelected: false,
          name: "privacy-policy",
        },
        {
          id: 3,
          title: "ratingPolicy",
          isSelected: false,
          name: "rating-policy",
        },
        {
          id: 4,
          title: "cookiesPolicy",
          isSelected: false,
          name: "cookies-policy",
        },
        {
          id: 5,
          title: "contact",
          isSelected: false,
          name: "contact-us",
        },
      ],
    };
  },
  methods: {
    select: function (id) {
      let name = "";
      this.titles.forEach((title) => {
        if (title.id == id) {
          title.isSelected = true;
          name = title.name;
          this.selectedTitle = name;
        } else {
          title.isSelected = false;
        }
      });
      let currentInfo = this.$router.currentRoute.params.type;
      if (currentInfo != name) {
        let queryObject = {};
        if (this.isApplication) {
          queryObject.isApplication = true;
        }
        this.$router.push({ name: "specific-info", params: { type: name }, query: queryObject });
      }
    },

    sendMessage: async function () {
      this.buttonLoading = true;
      this.errorInSendMessage = false;
      this.successfullySentMessage = false;
      this.successfullMessage = "";
      if (this.hasEmptyInputs()) {
        this.emptyInputs = true;
      } else {
        this.emptyInputs = false;
      }

      if (this.hasInvalidEmail()) {
        this.badEmail = true;
      } else {
        this.badEmail = false;
      }

      if (this.shouldSendMessage()) {
        let messageData = {
          name: this.userName,
          phone: this.userPhone,
          email: this.userEmail,
          message: this.userMessage,
        };

        let result = await MessageApi.postMessage(messageData);

        if (result.success) {
          this.errorInSendMessage = false;
          this.successfullySentMessage = true;
          this.successfullMessage = result.message;
        } else {
          this.errorInSendMessage = true;
          this.sendMessageError = result.message;
        }
      }
      this.buttonLoading = false;
    },

    hasEmptyInputs: function () {
      let emptyEmail = this.userEmail.replace(/\s+/g, "") === "";
      let emptyPhone = this.userPhone.replace(/\s+/g, "") === "";
      let emptyName = this.userName.replace(/\s+/g, "") === "";
      let emptyMessage = this.userMessage.replace(/\s+/g, "") === "";

      return emptyEmail || emptyPhone || emptyName || emptyMessage;
    },

    hasInvalidEmail: function () {
      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let isValidEmail = emailRegex.test(String(this.userEmail).toLowerCase());

      return !isValidEmail;
    },

    shouldSendMessage: function () {
      return !this.badEmail && !this.emptyInputs;
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    onClickOutside: function () {
      if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      } else if (this.isEntranceModalOpen) {
        this.closeEntrance();
      }
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
#body {
  display: flex;
  flex-direction: row;
  background: #f7f7f7;
  min-height: 60vh;
  width: 100vw;
}

#titles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  margin-left: 5%;
  width: 20%;
  background: #f7f7f7;
  margin-top: 10vh;
}

#info-text-container {
  height: 100%;
  width: 70%;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
}

#info-text {
  width: 80%;
}

#rating-policy-container,
#terms-of-service-container,
#privacy-policy-container,
#cookies-policy-container {
  height: 100%;
  width: 70%;
  margin-top: 10vh;
  margin-left: 2.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  #rating-policy-container,
  #terms-of-service-container,
  #privacy-policy-container,
  #cookies-policy-container {
    width: 80%;
    margin-top: 10vh;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#rating-policy-container > *,
#terms-of-service-container > *,
#privacy-policy-container > *,
#cookies-policy-container > * {
  width: 77.5%;
}
@media only screen and (max-width: 800px) {
  #rating-policy-container > *,
  #terms-of-service-container > *,
  #privacy-policy-container > *,
  #cookies-policy-container > * {
    width: 100%;
  }
}

.info-type {
  display: flex;
  align-items: center;
}

.info-type > h4 {
  color: #777;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 7.5vh;
  font-style: oblique;
}

#something-to-say {
  margin-top: 5vh;
  width: 70%;
  white-space: pre-wrap;
}

#something-to-say > h4 {
  margin-bottom: 3vh;
}

.info-title > h4 {
  font-size: 16px;
  font-weight: 600;
}

.info-paragraph > h4 {
  font-size: 13.5px;
  font-weight: 400;
  word-spacing: 1.45;
  letter-spacing: 0.3px;
  line-height: 1.45;
  color: #333;
  margin-bottom: 30px;
}

#title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background: transparent;
  border-radius: 4px;
  margin-bottom: 15px;
}

#title > h4 {
  margin: 0;
  color: #333;
  cursor: pointer;
  font-weight: 400;
  font-size: 13.5px;
}

#title > h4:hover {
  text-decoration: underline;
}

.selectedText {
  text-decoration: unset !important;
  cursor: unset !important;
  font-weight: 600 !important;
}

.selected {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  background: white !important;
}

#contact-us-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 5vh;
}

#name-and-phone {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#fluter-contact-details {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3vh;
}

#name,
#phone {
  width: 49%;
}

#email,
#message {
  width: 70%;
}

#contact-us-form > h4,
#name > h4,
#phone > h4,
#email-label,
#message-label {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  align-self: flex-start;
  margin-left: 10px;
}

.fluter-contact-header {
  color: #088c47;
  font-weight: 600;
  font-size: 16px;
  align-self: flex-start;
  margin-left: 10px;
}

input {
  border: none;
  border-radius: 18px;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: inset 2px 2px 2px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 35px;
  padding: 0 10px 0 10px;
  background: #fff;
  margin-bottom: 5%;
  -webkit-appearance: none !important;
}

input:focus {
  outline: none;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  background: white;

  justify-self: center;
  align-self: center;
  min-height: 10vh;
  max-height: 17vh;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  padding-left: 13px;
  padding-top: 7px;
  margin-bottom: 5%;
}

#button {
  width: 70%;
  background: linear-gradient(62deg, #198b4a, #04da6f);
  font-weight: 600;
  color: white;
  font-size: 16px;
  margin-bottom: 5%;
}

#button:disabled {
  opacity: 0.75;
}

.error-container {
  width: 70%;
  /* margin-bottom: 2.5vh; */
  transform: translateY(-2.5vh);
}

.error {
  margin: 0;
  color: #f33 !important;
  margin-left: 10px;
}

.success-container {
  width: 70%;
  /* margin-bottom: 2.5vh; */
  transform: translateY(-2.5vh);
}

.success {
  margin: 0;
  color: #198b4a !important;
  margin-left: 10px;
}

.alreadySent {
  background: #198b4a !important;
}

.hide {
  visibility: hidden;
  display: none;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

@media (max-width: 800px) {
  #body {
    flex-direction: column;
  }

  #titles-container {
    flex-direction: row;
    padding: 10px 10px 10px 10px;
    min-height: unset;
    /* height: 10vh; */
    /* min-height: 70px; */
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    margin-top: 5vh;
    overflow-x: scroll;
  }

  .selected {
    background: #f7f7f7 !important;
  }

  #title {
    margin-bottom: 0px;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    /* min-width: 100px;
    width: auto; */
  }

  #title > h4 {
    font-size: 12px;
  }

  #info-text-container {
    width: 100%;
    margin-top: 5vh;
  }

  .info-title > h4 {
    font-size: 14px;
  }

  .info-paragraph > h4 {
    font-size: 12px;
  }

  .info-type > h4 {
    font-size: 20px;
    margin-bottom: 4vh;
  }

  #contact-us-form {
    width: 100%;
    margin-bottom: 7.5vh;
  }

  #contact-us-form > h4,
  #name > h4,
  #phone > h4,
  #email-label,
  #message-label {
    font-size: 14px;
  }

  #name,
  #phone {
    width: 47%;
  }

  input {
    margin-bottom: 15%;
    font-size: 13px;
  }

  textarea {
    font-size: 13px;
  }

  #button {
    font-size: 14px;
    margin-bottom: 7.5%;
  }
}
</style>